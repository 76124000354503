import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Vacancy from "../components/Vacancy";

const Contacts = (props) => {
  
  return (
    <>
      <Helmet>
        <title>Контакты - ГосАвтоПолис страховая компания </title>
        <meta name="description" content="Страхование имущества от непредвиденных рисков. Охраните свое имущество от пожаров, воровства и других опасностей." />
      </Helmet>

      <Header />

      <section 
        className="breadcrumb-area d-flex align-items-center" 
        style={{backgroundImage: `url(${require('../images/bg/header-bg.png')})`, minHeight: '400px', }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  <h3 className="mb-4">Контакты</h3>
                  <p style={{color: '#08C802'}} >Доверие и защита в каждом полисе.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-right">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Главная</a></li>
                    <li className="breadcrumb-item active">Контакты</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pt-50 pb-20 p-relative">
        <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                <img
                  src={require("../images/contacts2.png")}
                  className="media-sm-img"
                  alt=""
                />
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 pl-100 p-sm-none">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title center-align mb-3 ">
                            <h5>
                                <span className="line2">
                                    {" "}<img src={require("../images/bg/circle_right.png")} alt="circle_right"/>
                                </span>
                                {" "}Свяжитесь с нами{" "}
                                <span className="line3">
                                    {" "}<img src={require("../images/bg/circle_left.png")}alt="circle_left"/>
                                </span>
                            </h5>
                            <h4>Мы готовы ответить на ваши вопросы и помочь вам получить надежную защиту для вашего автомобиля. Обратитесь к нам сегодня!</h4>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row align-items-center mt-5">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-4">
                        <div className="about-content s-about-content">
                        <ul className="ab-ul">
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">☎️</div>
                                </div>
                            <div className="text my-auto">
                                + 7959 5555 474 <br/>
                                Короткий номер: 474 <br/>
                                Звонить с 09:00 - 16:00
                            </div>
                            </li>
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">📩</div>
                                </div>
                            <div className="text my-auto">
                              lugansk@gosavtopolis.ru
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-4">
                        <div className="about-content s-about-content m-sm-none">
                        <ul className="ab-ul">
                            <li className="mb-5 w-100">
                                <div className="icon-box  mr-4">
                                    <div className="icon my-auto">📍</div>
                                </div>
                            <div className="text my-auto">
                                г. Луганск, <br/>
                                улица Ленина 236/30
                            </div>
                            </li>
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">⏰</div>
                                </div>
                            <div className="text my-auto">
                                пн-пт 09:00 - 16:00 <br/>
                                сб 09:00 - 14:00 <br/>
                                вс выходной
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>  
      </section>

      <section
        className="pb-100 pb-sm-50 offices"
        style={{ backgroundColor: "#ffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center">
                <h3 className="h2 font-weight-bold mt-4">Наши отделения 😎:</h3>
              </div>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
                ⚡️ г. Луганск:  <br/>
                ул. Ленина 236/30 (район бывшего Артемовского военкомата) <br/>
                Тел: <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Луганск: <br />
              кв. Комарова 16 <br />
              Тел: <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Луганск: <br />
              ул. Линева 68  <br />
              Тел: <a href="tel:+79591390568">+7(959)139-05-68</a> и <a href="tel:+79592002147">+7(959)200-21-47</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Алчевск: <br />
              ул. Московская, дом 4 <br />
              Тел: <a href="tel:+79595051577">+7 959 505 1577</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Алчевск: <br />
              проспект Ленина 28. <br />
              Тел: <a href="tel:+79591300224">+7(959)130-02-24</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Лутугино: <br />
              ул. Ленина 120А Универмаг (2-ой этаж) <br />
              Тел: <a href="tel:+79592257194">+7(959)225-71-94</a> и <a href="tel:+79592041109">+7(959)204-11-09</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Старобельск <br />
              ул. Трудовая 23/3 <br />
              Тел: <a href="tel:+79595199577">+7(959)51-99-577</a> и <a href="tel:+79595199573">+7(959)51-99-573</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ пгт. Макровка <br />
              ул. Ленина д.5/2 <br />
              Тел: <a href="tel:+79595215442">+7(959)521-54-42</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12">
              <h5>
              ⚡️ г. Счастье <br />
              ул. Донецкая, д.123 <br />
              тел. <a href="tel:+79595810110">+7(959)58-10-110</a>
              </h5>
            </div>
            <div className="col-md-6 mb-4 item col-12 mx-auto">
              <h5>
              ⚡️ пгт. Станица Луганская <br />
              ТЦ "Квадрат" <br />
              Тел. <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
              </h5>
            </div>
          </div>
          <h4 className="mb-4">Так же наши агенты есть в городах:</h4>
          <div className="row">
            <div className="col-md-6 mb-4 pb-3 item col-12">
              <h5>
              ⚡️ пгт. Белокуракино <a href="tel:+79595189093">+7(959)518-90-93</a> <br/>
              ⚡️ г. Алчевск <a href="tel:+79591538302">+7(959)153-83-02</a> и <a href="tel:+79591003317">+7(959)100-33-17</a> <br/>
              ⚡️ г. Перевальск <a href="tel:+79591538302">+7(959)153-83-02</a> и <a href="tel:+79591003317">+7(959)100-33-17</a> <br/>
              ⚡️ г. Краснодон <a href="tel:+79591121870">+7(959)112-18-70</a> <br/>
              ⚡️ г. Красный Луч <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              </h5>
            </div>
            <div className="col-md-6 mb-4 pb-3 item col-12">
              <h5>
              ⚡️ г. Антрацит <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              ⚡️ г. Молодогвардейск <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              ⚡️ г. Свердловск <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              ⚡️ г. Стаханов <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              ⚡️ п. Сватово <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
              </h5>
            </div>
          </div>
        </div>
      </section>

      <Vacancy/>

      <Footer/>
    </>
  );
};

export default Contacts;